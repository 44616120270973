import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../store"
import { setTestDetail } from "../../actions";

export default function TestCheckItemDis(props) {
    const { dispatch } = useContext(StoreContext);
    const test = props.test.te;
    const callback = props.test.callback;
    //console.log("autotest",props);
    const [checkedState, setData] = useState(false);

    const handleOnChange = (event) => {
        setData(event.target.checked);
        callback(event);
    };
    
    return(
        <div className="select_item">
            <input
                className="select_box box"
                type="checkbox"
                id={`custom-checkbox-${test.id}`}
                name={test.id}
                value={test.id}
                checked={checkedState}
                onChange={handleOnChange}
                disabled={true}
            />
            <p className="select_item_desc dis">{test.test_name}</p>
        </div>
    );
}