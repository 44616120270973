import React, { useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import './App.css';
import Login from './pages/Login'

import AutoTestCon from './pages/test/AutoTestCon'
import AutoTestDis from './pages/test/AutoTestDis'
import ManualTest from './pages/test/ManualTest'

import { StoreProvider } from "./store";

function App() {
  const [user, setUser] = useState({});

  //console.log('App enter:', user);

  return (
    <StoreProvider>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="/login" component={Login} />
          <Route path="/autotestcon" component={AutoTestCon} />
          <Route path="/autotestdis" component={AutoTestDis} />
          <Route path="/manualtest" component={ManualTest} />
        </Switch>
      </BrowserRouter>
    </StoreProvider>
  );
}

export default App;