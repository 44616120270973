// SET CONTENT
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const SET_PAGE_CONTENT = 'SET_PAGE_CONTENT';
export const SET_DATA_DETAIL = "SET_DATA_DETAIL";
export const SET_NAVBAR_ACTIVEITEM = 'SET_NAVBAR_ACTIVEITEM';

// REQUEST FIREBASE CUSTOMERS
export const BEGIN_DATA_REQUEST = "BEGIN_DATA_REQUEST";
export const SUCCESS_DATA_REQUEST = "SUCCESS_DATA_REQUEST";
export const FAIL_DATA_REQUEST = "FAIL_DATA_REQUEST";

// REQUEST FIREBASE LOGIN
export const BEGIN_LOGIN_REQUEST = "BEGIN_LOGIN_REQUEST";
export const SUCCESS_LOGIN_REQUEST = "SUCCESS_LOGIN_REQUEST";
export const FAIL_LOGIN_REQUEST = "FAIL_LOGIN_REQUEST";
export const REMEMBER_LOGIN = "REMEMBER_LOGIN";

// UPDATE USER INFO
export const BEGIN_UPDATE_USERINFO = "BEGIN_UPDATE_USERINFO";
export const SUCCESS_UPDATE_USERINFO = "SUCCESS_UPDATE_USERINFO";
export const FAIL_UPDATE_USERINFO = "FAIL_UPDATE_USERINFO";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";

// SET USER　INFO
export const BEGIN_REQUEST_USERINFO = "BEGIN_REQUEST_USERINFO";
export const SUCCESS_REQUEST_USERINFO = "SUCCESS_REQUEST_USERINFO";
export const FAIL_REQUEST_USERINFO = "FAIL_REQUEST_USERINFO";

export const SAVE_ROBOT = "SAVE_ROBOT";
export const BEGIN_UPDATE_ROBOT = "BEGIN_UPDATE_ROBOT";
export const SUCCESS_UPDATE_ROBOT = "SUCCESS_UPDATE_ROBOT";
export const FAIL_UPDATE_ROBOT = "FAIL_UPDATE_ROBOT";

//SELECTED TEST
export const SET_SELECTED_TEST = "SET_SELECTED_TEST";