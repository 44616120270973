import { Layout } from 'antd';
import { useContext , useEffect} from "react"; 
import NavBar from "../../components/NavBar";
import AutoTestDisconnect from "../../components/test/AutoTestDisconnect";

const { Content } = Layout;

function AutoTestDis() {
   return (
      <Layout className="container main-layout">
            <NavBar />
         <Layout className="bg-gray">
            <Content className="layout-content">
               <AutoTestDisconnect />
            </Content>
         </Layout>
      </Layout>
   );
}

export default AutoTestDis;