import React, { useContext, useEffect, useState } from "react";
import { Button, Switch, Progress } from 'antd';
import { StoreContext } from "../../store"
import { setTestDetail } from "../../actions";
import { Link } from 'react-router-dom';
import { useCountUp } from 'react-countup';

export default function ManualTestItem({test}) {
    const { dispatch } = useContext(StoreContext);

    const [imgvisible, setimgVisible] = useState(false);
    const [switchvisible, setswitchVisible] = useState(false);
    const [stopvisible, setstopVisible] = useState(false);
    const [finishvisible, setfinishVisible] = useState(false);
    const [failvisible, setfailVisible] = useState(false);
    const [testvisible, settestVisible] = useState(false);
    const [progressvisible, setprogressVisible] = useState(false);
    const [testvaluevisible, settestvalueVisible] = useState(false);

    const testResultStart = () => {
        console.log("Test Result:", true);
        setstopVisible(false);
        setfailVisible(false);
        settestVisible(true);
    };
    const testResultStop = () => {
        console.log("Test Result:", false);
        setstopVisible(true);
        setfailVisible(true);
        settestvalueVisible(false);
    };

    const testSuccess = (test) => {
        console.log(`Test Result: ${test.test_name}`, true);
        setfinishVisible(true);
    };
    const testFail = (test) => {
        console.log(`Test Result: ${test.test_name}`, false);
        setfinishVisible(true);
        setfailVisible(true);
    };
    
    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
        settestvalueVisible(true);
    };

    const testProgress = (test) => {
        console.log(`Test Result: ${test.test_name}`, true);
        setprogressVisible(true);
    };

    const countUpRef = React.useRef(null);
    const { pauseResume } = useCountUp({
        ref: countUpRef,
        start: 0,
        end: 1234567,
        delay: 1000,
        duration: 5,
        onPauseResume: () => console.log('Paused or resumed!'),
    });

    return(
        <div>
            {testvisible?(
                <div className="select_testcard"
                    onClick={() => {
                        setTestDetail(dispatch, test.id);
                    }}
                >
                    {switchvisible?(
                        <p className="test_title">{test.test_name}</p>
                    ):(
                        <div className="test_page_layout">
                            <p className="test_title">{test.test_name}</p>
                            <Switch onChange={pauseResume} className="test_switch"/>
                        </div>
                    )}
                    <p className="test_title">Status:</p>
                    <p className="test_title test_desc" ref={countUpRef} />
                    <Button
                        htmlType="submit" className="test_button_stop" 
                        onClick={testResultStop}
                    >
                        {test.test_btn2}
                    </Button>
                </div>
            ):(
                <div className="select_testcard">
                    <Link 
                        onClick={() => {
                            setTestDetail(dispatch, test.id);
                            if(settestVisible(true)){
                                console.log(`${test.test_name}`, false);
                            }else{
                                console.log(`${test.test_name}`, true);
                            }
                        }}>
                        <p className="test_title">{test.test_name}</p>
                    </Link>
                    {imgvisible? null:(<img src={test.image_progress} onClick={testProgress}/>)}
                    <p className="test_title">{test.manual_test_desc}</p>
                    <Button
                        htmlType="submit" className="test_button" 
                        onClick={testResultStart}
                        >
                        {test.test_btn}
                    </Button>
                    {progressvisible? (<Progress percent={100} showInfo={false} status="active"/>):null}
                </div>
            )}
        </div>
    );
}