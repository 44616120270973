import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Row, Col, Progress, Modal, Switch } from 'antd';
import { Scrollbars } from "react-custom-scrollbars";
import test from "../../json/test.json"
import Checkbox from "./Checkbox";
import ManualTestItem from "./ManualTestItem";

export default function ManualTestDetail(props) {
    const history = useHistory();
    const disconnect = () => {
        history.push("/autotestdis");
    };
    const connect = () => {
        history.push("/manualtest");
        setVisible(false);
        setDisabled();
        setfinishVisible(false);
        settestVisible(false);
        setstopVisible(true);
    };
    
    const testResultStart = () => {
        console.log("Test Result:", true);
        setstopVisible(false);
        setfailVisible(false);
        settestVisible(true);
        setswitchVisible(true);
    };
    const testResultStop = () => {
        console.log("Test Result:", false);
        setstopVisible(true);
        setfailVisible(true);
    };

    const testFinish = () => {
        console.log("Test Finish:", true);
        setfinishVisible(true);
        setfailVisible(false);
    };

    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [list, setList] = useState([]);
    const [select, setSelect] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [visible, setVisible] = useState(false);
    const [btnvisible, setbtnVisible] = useState(false);
    const [switchvisible, setswitchVisible] = useState(false);
    const [imgvisible, setimgVisible] = useState(false);
    const [stopvisible, setstopVisible] = useState(false);
    const [finishvisible, setfinishVisible] = useState(false);
    const [failvisible, setfailVisible] = useState(false);
    const [testvisible, settestVisible] = useState(false);

    useEffect(() => {
        setList(test);
    }, [list]);

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(list.map(li => li.id));
        if (isCheckAll) {
        setIsCheck([]);
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        var selected = [...isCheck, id];
        setIsCheck([...isCheck, id]);
        //console.log("ckeck:", [...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
            selected = isCheck.filter(item => item !== id);
            //console.log("ischeck:",isCheck.filter(item => item !== id));
        }
    };

    const AddTest = (checked) => {
        var selectedTest = [];
        isCheck.forEach(function(value){
            test.forEach(function(testitem){
                if(testitem.id === value){
                    if(testitem.manualtest_btn_type === 1 && testitem.manual_sw_type === 1){
                        setbtnVisible(true);
                        setswitchVisible(true);
                        selectedTest.push(testitem);
                    }else{
                        setimgVisible(true);
                        selectedTest.push(testitem);
                    }
                }
            });
        });
        console.log(selectedTest);  
        setSelect(selectedTest);
        setVisible(true);
        console.log(isCheck);
        setDisabled(checked);
    }

    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
    };

    return (
        <div> 
            <Row>
                <Col span={7} className="select_bar">
                    <Scrollbars>
                        <div className="select_title">
                            <Checkbox
                                type="checkbox"
                                name="selectAll"
                                id="selectAll"
                                handleClick={handleSelectAll}
                                isChecked={isCheckAll}
                                disabled={disabled}
                            />
                            {isCheckAll ? <p className="select_desc"> {isCheck.length}/38 selected </p> :<p className="select_desc"> Selected All</p> }
                            {visible ?(
                                <Button
                                    htmlType="submit" className="run_select_button" 
                                    onClick={connect}
                                    >
                                    STOP TEST
                                </Button>
                            ):(
                                <Button
                                    htmlType="submit" className="run_select_button" 
                                    onClick={AddTest}
                                    >
                                    RUN SELECT TEST
                                </Button>
                            )}
                        </div>
                        {test.map(({ id, test_name }) => (
                            <div className="select_item">
                                <Checkbox
                                    key={id}
                                    type="checkbox"
                                    name={test_name}
                                    id={id}
                                    handleClick={handleClick}
                                    isChecked={isCheck.includes(id)}
                                    disabled={disabled}
                                />
                                <p className="select_item_desc">{test_name}</p>
                            </div>
                        ))}
                    </Scrollbars>
                </Col>
                <Col span={12}>
                    <Scrollbars>
                        {visible?<div className="testcard">
                            <Row gutter={[0, 0]}>
                                {select.map(test => (
                                    <Col 
                                    key={test.id} 
                                    xl={{ span: 12 }}
                                    xxl={{ span: 12 }}
                                    >
                                    <ManualTestItem test={test}/>
                                    </Col>
                                ))}
                            </Row>
                        </div>:null}
                    </Scrollbars>
                </Col>
                <Col span={5}>
                    <div className="select_robotdetail">
                        <div className="div">
                            <p className="select_robot_title">NEXMOW M1</p>
                            <img src="/images/device_image.png" />
                            <p className="select_robot_num">Serial number</p>
                            <p className="select_robot_desc">M1B2A1MP000B000</p>
                            <p className="select_robot_num tit">Customer</p>
                            <p className="select_robot_desc">Unknown</p>
                            <p className="select_robot_num tit">Run time</p>
                            <p className="select_robot_desc">12345 Hours</p>
                            <p className="select_robot_num tit">State of charge</p>
                            <Progress percent={100} steps={4}/>
                            <p className="select_robot_num tit">Latest save</p>
                            <p className="select_robot_desc">2022-06-01 10:00AM</p>
                            <p className="select_robot_num tit">Firmware</p>
                            <p className="select_robot_desc">v12345.000.000</p>
                            <Button
                                htmlType="submit" className="robot_disconnect_btn" onClick={disconnect}
                                >
                                DISCONNECT
                            </Button> 
                            <Button
                                htmlType="submit" className="robot_export_btn" disabled={true}
                                >
                                EXPORT REPORT
                            </Button> 
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};