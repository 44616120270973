import React from "react";

const Checkbox = ({ id, type, name, handleClick, isChecked, disabled }) => {
  return (
    <input
      className="select_box box"
      id={id}
      name={name}
      type={type}
      onChange={handleClick}
      checked={isChecked}
      disabled={disabled}
    />
  );
};

export default Checkbox;