import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Input, Button, Text, Row, Col, Progress } from 'antd';
import { StoreContext } from "../../store";
import { Scrollbars } from "react-custom-scrollbars";
import test from "../../json/test.json"
import TestCheckItemDis from "./TestCheckItemDis";
import { saveRobot, setRobotDetail } from "../../actions"
import firebase from "firebase";
import _ from "lodash";

export default function AutoTestDisconnect() {
    const {state: { robotDetail:{ robot }}, dispatch } = useContext(StoreContext);
    const history = useHistory();

    const robotStateCheck = (robot) => {
        //Error cases

        //1. no such robot
        if(robot === undefined){
            alert("Invalid PSN Serial number, please check and re-enter again!");
            return false;
        }
        //2. offline, connection_status=='offline'
        if(_.has(robot, 'connection_status') && robot.connection_status === 'offline'){
            alert(robot.warranty.psn + " is not connected to Nexmow network, please check LTE modem, SIM card and turn on the robot again.");
            return false;
        }
        //3. not in idle state,     config.dev_status.running_mode != 0
        if(_.has(robot, 'config') && _.has(robot.config, 'dev_status') && _.has(robot.config.dev_status, 'running_mode') && robot.config.dev_status.running_mode !== 0){
            alert(robot.warranty.psn + " is in use, please stop the task and connect again.");
            return false;
        }
        //4. in OTA  ota_info.ota_state != 'inactive'
        if(_.has(robot, 'ota_info') && _.has(robot.ota_info, 'ota_state') && robot.ota_info.ota_state !== 'inactive'){
            alert(robot.warranty.psn + " is in OTA process, please wait for OTA finished!");
            return false;
        }
        //5. in diag  diag_info/diag_state != 'inactive'
        if(_.has(robot, 'diag_info') && _.has(robot.diag_info, 'diag_state') && robot.diag_info.diag_state !== 'inactive'){
            alert(robot.warranty.psn + " is in diagnostic testing, please wait for diagnostic finished! If robot is not under test, try to reboot the robot and connect it again!");
            return false;
        }
        return true;
    }

    const connectRobot = (robot) => {
        var robotRef = firebase.firestore().collection("robots").doc(robot.id);
        console.log('connectRobot: ', robot.id, robotRef);
        robotRef.set( 
            {diag_info:{
                        diag_state:'active',
                        connect_time:firebase.firestore.FieldValue.serverTimestamp()
                        }
            }, { merge: true });
    }

    const connect = (values) => {
        let robotPSN = document.getElementsByName("psn_field")[0].value;
        console.log('robotPSN:', robotPSN);

        const docRef = firebase.firestore().collection("robots").where("warranty.psn", "==", robot.psn);
        docRef.get().then((querySnapshot) => {
            let robots = [];
            querySnapshot.forEach((robot) => {
                let data = robot.data();
                data.id = robot.id
                console.log('robot id:', data);
                robots.push(data)
            });
            //console.log("Document data:", robots);
            if(robotStateCheck(robots[0])){
                connectRobot(robots[0]);
                saveRobot(dispatch, robots[0]); //always one robot per psn 
                history.push("/autotestcon");
            }
        })
        .catch((error) => {
            console.log("Error getting documents: ", error);
        }); 

        /*
        if(values = robot.psn){
            //history.push("/autotestcon");
            saveRobot(dispatch, values);
            setRobotDetail(dispatch, robot); 
        }else{
            alert("Wrong Serial number!")
        }*/
    };
    const handleChange = (event) => {
        console.log(`${event.target.name}: ${event.target.value}`)
        if(event.target.name === "psn_field"){
            robot.psn = event.target.value;
        }
/* 
        const docRef = firebase.firestore().collection("robots").where("warranty.psn", "==", robot.psn);
        docRef.get().then((querySnapshot) => {
            let robots = [];
            querySnapshot.forEach((robot) => {
                robots.push(robot.data())
            });
            console.log("Document data:", robots);
        })
        .catch((error) => {
            console.log("Error getting documents: ", error);
        });  */
    }; 
    // 輸入PSN比對，找到相同PSN的firebase data

    return (
        <div> 
            <Row>
                <Col span={7} className="select_bar">
                    <Scrollbars>
                        <div className="select_title">
                            <input
                                className="select_box box"
                                type="checkbox"
                                disabled={true}
                            />
                            <p className="select_desc dis"> {test.length} selected</p>
                            <Button
                                htmlType="submit" className="run_select_button" disabled={true}
                                >
                                RUN SELECT TEST
                            </Button>
                        </div>
                        <div className="select_detail">
                            {test.map(test => (
                                <Col
                                    key={test.id}
                                    >
                                    <TestCheckItemDis test={{te:test}}/>
                                </Col>
                            ))}
                        </div>
                    </Scrollbars>
                </Col>
                <Col span={12}>
                    <div className="robot_notice_card">
                        <p className="robot_notice_desc">Please find the Serial Number on M1</p>
                        <img className="robot_notice_pic" src="/images/position_image.png" />
                        <img className="robot_notice_pic" src="/images/serial_no_image.png" />
                    </div>
                </Col>
                <Col span={5}>
                    <div className="select_robotdetail">
                        <div>
                            <p className="select_robot_title">NEXMOW M1</p>
                            <img src="/images/device_disable_image.png" />
                            <p className="select_robot_num">Serial number</p>
                            <Input className="serial_num" placeholder="Please enter serial number" name="psn_field" autoFocus onChange={handleChange} maxLength="20"/>
                            <p className="select_robot_desc">Customer</p>
                            <p className="select_robot_desc">NA</p>
                            <p className="select_robot_desc">Run time</p>
                            <p className="select_robot_desc">NA</p>
                            <p className="select_robot_desc">State of charge</p>
                            <Progress percent={0} steps={4} showInfo={false}/>
                            <p className="select_robot_desc">Firmware</p>
                            <p className="select_robot_desc">NA</p>
                            <Button
                                htmlType="submit"className="robot_connect_btn" onClick={connect}
                                >
                                CONNECT
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
};