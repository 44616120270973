import React, { useEffect, useState } from "react";
import { Button, Progress } from 'antd';

export default function AutoTestItem({test, cmdTunnel, setCmdTunnel}) {
    const [result, setresult] = useState(true);
    const [value, setvalue] = useState(0);
    const [testState, settestState] = useState(1);

    //console.log("AutoTestItem:", test.id, testState, Math.floor(Math.random() * 10000));

    const testResultYes = (test) => {
        console.log(`Test Result: ${test.test_name}`, true);
        settestState(3);
        setresult(true);
        setCmdTunnel({'command':'next_test', 'test_case': cmdTunnel.test_case});
    };
    const testResultNo = (test) => {
        console.log(`Test Result: ${test.test_name}`, false);
        settestState(3);
        setresult(false);
        setCmdTunnel({'command':'next_test', 'test_case': cmdTunnel.test_case});
    };

    const handleCommnad = (command) => {
        console.log(" This is my test case", test.id, cmdTunnel);

        if(cmdTunnel.command === 'u_ready'){
            settestState(2);
        }
        if(cmdTunnel.command === 'u_result' && (test.autotest_btn_type !== 1)){
            settestState(3);
            let value = parseFloat(cmdTunnel.value);
            
            setvalue(value.toFixed(2));
            if(cmdTunnel.result){
                setresult(true);
            }else{
                setresult(false);    
            }
            setCmdTunnel({'command':'next_test', 'test_case': cmdTunnel.test_case});
        }
    }

    useEffect(() => {
        if(cmdTunnel.test_case === parseInt(test.id)){
            handleCommnad(cmdTunnel);
        }
        if(cmdTunnel.command === 'reset'){
            settestState(1);
        }
    }, [cmdTunnel]);

    return(
        <div>
            {(testState==1)?(
                <div className="select_testcard">
                    <p className="test_title">{test.test_name}</p>
                    <img src={test.image_progress}/>
                    <p className="test_title">{test.test_desc1}</p>
                </div>
            ):(null)}
            {(testState==2)?(
                <div className="select_testcard">
                    <p className="test_title">{test.test_name}</p>
                    <img src={test.image_test} className="image_test"/>
                    <p className="test_title test_desc">{test.test_desc2}</p>
                    {(test.autotest_btn_type === 1)?(
                        <div>
                            {(cmdTunnel.command === 'u_result')?(
                                <div className="test_page_layout">
                                    <Button
                                        htmlType="submit" className="test_button"
                                        onClick={testResultYes} 
                                        >
                                        {test.type1}
                                    </Button>
                                    <Button
                                        htmlType="submit" className="test_button"
                                        onClick={testResultNo}
                                        >
                                        {test.type2}
                                    </Button>
                                </div>
                            ):(
                                <div className="test_page_layout">
                                    <Button
                                        htmlType="submit" className="test_button"
                                        onClick={testResultYes} disabled={true}
                                        >
                                        {test.type1}
                                    </Button>
                                    <Button
                                        htmlType="submit" className="test_button"
                                        onClick={testResultNo} disabled={true}
                                        >
                                        {test.type2}
                                    </Button>
                                </div> 
                            )}
                        </div>
                    ):(
                        null
                    )}
                    <Progress percent={100} showInfo={false} status="active"/>
                </div>
            ):(null)}
            {(testState==3)?(
                <div className="select_testcard">
                    <p className="test_title">{test.test_name}</p>
                    {result?(
                        <img src={ test.image_finish }/>
                    ):(
                        <img src={ test.image_fail }/>
                    )}
                    <p className="test_title">{test.test_desc3}{value}</p>
                </div>
            ):(null)}    
        </div>
    );
}