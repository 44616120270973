import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Row, Col, Progress } from 'antd';
import { Scrollbars } from "react-custom-scrollbars";
import { StoreContext } from "../../store"
import { requestRobotDetail } from "../../actions"
import { saveRobot, setRobotDetail } from "../../actions"
import test from "../../json/test.json"
import Checkbox from "./Checkbox";
import AutoTestItem from "./AutoTestItem";
import firebase from "firebase";
import _ from "lodash";
import {HashLoader} from "react-spinners";

export default function AutoTestConnect() {
    const { state:{ robotDetail:{ robot } }, dispatch } = useContext(StoreContext);
    const history = useHistory();
    
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [list, setList] = useState([]);
    const [select, setSelect] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [visible, setVisible] = useState(false);
    const [testing, setTesting] = useState(false);  //Use this to distinguish if test is running
    const [deviceReady, setdeviceReady] = useState(false);
    //const [finishvisible, setfinishVisible] = useState(false);

    //const [dataToShow, setData] = useState([]);
    const [cmdTunnel, setCmdTunnel] = useState({'test_case':0});
    const [idleTimer, setIdleTimer] = useState(0);
    
    const robot_id = robot.id;//'00000000bd34b696';
    //M1B2A1MP020B
    var diag_ref;
    var unsubscribe;
    const onCollectionUpdate = (querySnapshot) => {
        querySnapshot.forEach((doc) => {
            setCmdTunnel({'test_case':doc.data().test_case, 'command':doc.data().command,'result':doc.data().result, 'value': doc.data().value});
        });
        querySnapshot.forEach((doc) => {
            doc.ref.delete();
        });
    }

    useEffect(() => {
        diag_ref = firebase.firestore().collection('diag_commands').doc(robot_id).collection('u_queue');
        unsubscribe = diag_ref.onSnapshot(onCollectionUpdate);
        return () => {
            unsubscribe();
        };
    }, []);


    useEffect(() => {
        let unsub = firebase.firestore().collection("robots").doc(robot.id).onSnapshot((doc) => {
            //console.log("Current data: ", doc.data());
            let robot = doc.data();
            console.log("doc listener");
            
            if(_.has(robot, 'config') && _.has(robot.config, 'dev_status') && _.has(robot.config.dev_status, 'running_mode') && robot.config.dev_status.running_mode !== 0){
                disconnect();
                alert(robot.warranty.psn + " is in use, please stop the task and connect again.");
            }
            //4. in OTA  ota_info.ota_state != 'inactive'
            if(_.has(robot, 'ota_info') && _.has(robot.ota_info, 'ota_state') && robot.ota_info.ota_state !== 'inactive'){
                disconnect();
                alert(robot.warranty.psn + " is in OTA process, please wait for OTA finished!");
            }
        });
        return () => {
            unsub();
        };
    }, []);

    const AddTest = (checked) => {
        var selectedTest = [];
        isCheck.forEach(function(value){
            test.forEach(function(testitem){
                if(testitem.id === value){
                    selectedTest.push(testitem);
                }
            });
        });
        console.log(selectedTest);  
        setSelect(selectedTest);
        setVisible(true);
        setTesting(true);
        console.log(isCheck);
        setDisabled(checked);
        //console.log(selectedTest);
        setCmdTunnel({'test_case':0, command:'reset'})
        sendCommnad('d_start', isCheck[0]);
        resetTimer();
    }
    async function disconnectRobot(robot){
        sendCommnad('d_deactivate', 0);
        var robotRef = firebase.firestore().collection("robots").doc(robot.id);
        await robotRef.set( 
            {diag_info:{
                        diag_state:'inactive'
                        }
            }, { merge: true });
    }
    const disconnect = () => {
        resetTimer();
        history.push("/autotestdis");
        disconnectRobot(robot);
        saveRobot(dispatch, {}); 
    };

    const setTimer = (timeout) => {
        //console.log('setTimer');
        if(idleTimer != 0) return;
        const timer = setTimeout(() => {
            console.log("timeout:", idleTimer);
            disconnect();
            alert("Idle too long time, disconnect from robot");
          }, timeout);
        setIdleTimer(timer); 
        console.log('setTimer:', timer);
    }

    const resetTimer = () => {
        console.log('resetTimer:', idleTimer);
        clearTimeout(idleTimer);
        setIdleTimer(0); 
    }

    const StopTest = () => {
        //history.push("/autotestcon");
        //setVisible(false);   // make cards invisible
        setDisabled(false);    // make checkbox can be selected again
        setTesting(false); 
        setTimer(300*1000);
        //setfinishVisible(false); 
    };

    useEffect(() => {
        sendCommnad('d_activate', 0);
        setTimer(300*1000);
    }, []);

    // useEffect(() => {
    //     window.addEventListener('beforeunload', alertUser)
    //     window.addEventListener('unload', handleTabClosing)
    //     return () => {
    //         window.removeEventListener('beforeunload', alertUser)
    //         window.removeEventListener('unload', handleTabClosing)
    //     }
    // })
    
    // const handleTabClosing = () => {
    //     disconnectRobot(robot);
    // }
    
    // const alertUser = (event:any) => {
    //     event.preventDefault()
    //     event.returnValue = ''
    // }

    const log_command = (command, test_case) => {
        console.log("log_command", command, test_case);
        //var doc_name = command + '_' + String(test_case).padStart(2, '0');
        let commands = firebase.firestore().collection('diag_commands').doc(robot_id);
        const cmd_ctx = {
            "command": command,
            "test_case": parseInt(test_case),
            "timestamp": firebase.firestore.FieldValue.serverTimestamp()
        }
        let result = commands.collection('log_queue').add(cmd_ctx);
    }

    const  sendCommnad = (command, test_case) => {
        console.log("sendCommnad", command, test_case);
        var doc_name = command + '_' + String(test_case).padStart(2, '0');
        let commands = firebase.firestore().collection('diag_commands').doc(robot_id);
        const cmd_ctx = {
            "command": command,
            "test_case": parseInt(test_case),
            "timestamp": firebase.firestore.FieldValue.serverTimestamp()
        }
        let result = commands.collection('d_queue').doc(doc_name).set(cmd_ctx);
        log_command(command, test_case)
    }
  
    const startNextTest = (test_case, command) => {
        let curTestIndex = isCheck.findIndex((element) => element == test_case);
        if(curTestIndex == isCheck.length-1){
            console.log("end of tests:");
            StopTest();
            return;
        }
        setCmdTunnel({'test_case':0})
        setTimeout(function(){
            sendCommnad('d_start', isCheck[curTestIndex+1]);
        }, 3000);
        //console.log("startNextTest:", isCheck[curTestIndex+1]);
    }

    useEffect(() => {
        
        //console.log("Connected robot", robot);
        if(cmdTunnel.command === 'next_test'){
            startNextTest(cmdTunnel.test_case);
        }
        if(cmdTunnel.command === 'u_activate'){
            console.log('device ready');
            setdeviceReady(true);
        }
        if(cmdTunnel.command === 'u_deactivate'){
            console.log('device killed');
        }
        

    }, [cmdTunnel]);

    
    useEffect(() => {
        setList(test);
    }, [list]);

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(list.map(li => li.id));
        if (isCheckAll) {
        setIsCheck([]);
        }
    };

    function sort_list(list){
        function aesc( a, b ) {
            if ( parseInt(a) < parseInt(b)){
                return -1;
            }
            if ( parseInt(a) > parseInt(b)){
                return 1;
            }
            return 0;
        }
        
        list.sort(aesc)
        return list;
    }

    const handleClick = e => {
        const { id, checked } = e.target;
        var selected = [...isCheck, id];
        var sortedIsCheck = sort_list([...isCheck, id]);
        setIsCheck(sortedIsCheck);
        console.log("ckeck:", sortedIsCheck);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
            selected = isCheck.filter(item => item !== id);
            console.log("ischeck:", isCheck.filter(item => item !== id));
        }
    };
    return (
        <div> 
            <Row>
                <Col span={7} className="select_bar">
                    <Scrollbars>
                        <div className="select_title">
                            <Checkbox
                                type="checkbox"
                                name="selectAll"
                                id="selectAll"
                                handleClick={handleSelectAll}
                                isChecked={isCheckAll}
                                disabled={disabled}
                            />
                            {isCheckAll ? <p className="select_desc"> {isCheck.length} / {test.length} selected </p> :<p className="select_desc"> Selected All</p> }
                                
                                <div>
                                    {testing ?(
                                        <Button
                                            htmlType="submit" className="run_select_button" 
                                            onClick={StopTest}
                                            >
                                            STOP TEST
                                        </Button>
                                    ):(
                                        <div>
                                        {deviceReady?(
                                            <Button
                                                htmlType="submit" className="run_select_button" 
                                                onClick={AddTest} 
                                                >
                                                RUN SELECT TEST
                                            </Button>
                                        ):(
                                            <Button
                                                htmlType="submit" className="run_select_button" 
                                                onClick={AddTest} disabled={true}
                                                >
                                                RUN SELECT TEST
                                            </Button>
                                        )}
                                        </div>
                                    )}
                                </div>
                        </div>
                        {test.map(({ id, test_name }) => (
                            <div className="select_item">
                                <Checkbox
                                    key={test_name}
                                    type="checkbox"
                                    name={test_name}
                                    id={id}
                                    handleClick={handleClick}
                                    isChecked={isCheck.includes(id)}
                                    disabled={disabled}
                                />
                                <p className="select_item_desc">{test_name}</p>
                            </div>
                        ))}
                    </Scrollbars>
                </Col>
                <Col span={12}>
                    <Scrollbars>
                        {deviceReady?(
                            null
                        ):(
                            <div>
                                <HashLoader
                                    color="#004ea8"
                                    cssOverride={{}}
                                    loading
                                    size={100}
                                    className='hashloader'
                                />
                                <p className="hashloader_word">Robot Connecting ...</p>
                            </div>
                        )}
                        {visible?(
                            <div className="testcard">
                                <Row gutter={[0, 0]}>
                                    {select.map(test => (
                                        <Col 
                                        key={test.id} 
                                        xl={{ span: 12 }}
                                        xxl={{ span: 12 }}
                                        >
                                        <AutoTestItem test={test} cmdTunnel={cmdTunnel} setCmdTunnel={setCmdTunnel}/>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        ):(
                            null
                        )}
                    </Scrollbars>
                </Col>
                <Col span={5}>
                    <div className="select_robotdetail">
                        <div className="div">
                            <p className="select_robot_title">NEXMOW M1</p>
                            <img src="/images/device_image.png"/>
                            <p className="select_robot_num">Serial number</p>
                            <p className="select_robot_desc">{robot.warranty.psn}</p>
                            <p className="select_robot_num tit">Customer</p>
                            <p className="select_robot_desc">{robot.company}</p>
                            <p className="select_robot_num tit">Run time</p>
                            <p className="select_robot_desc">{robot.usage_data.Lifetime.blade_running_time	}</p>
                            <p className="select_robot_num tit">State of charge</p>
                            <Progress percent={robot.config.dev_status.guage_status*25} steps={4} showInfo={false}/>
                            <p className="select_robot_num tit">Firmware</p>
                            <p className="select_robot_desc">{robot.config.sw_hw_version[0]}</p>

                            <Button
                                htmlType="submit" className="robot_disconnect_btn" onClick={disconnect}
                                >
                                DISCONNECT
                            </Button> 
                            <Button
                                htmlType="submit" className="robot_export_btn" disabled={true}
                                >
                                EXPORT REPORT
                            </Button> 
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};