import firebase from 'firebase/app'
import 'firebase/auth';
import 'firebase/firestore';

import test from "../json/test.json";
export const getJSON = (url) => {
  switch (url) {
    case "/autotestcon":
      return test;
    case "/manualtest":
      return test;
  }
};
// INITIALIZE FIREBASE
//Dev
// const firebaseConfig = {
//   apiKey: "AIzaSyDFLisIU7BWgDFbu91RkgQH0jVbdPPRUo4",
//   authDomain: "productm-dev.firebaseapp.com",
//   databaseURL: "https://productm-dev.firebaseio.com",
//   projectId: "productm-dev",
//   storageBucket: "productm-dev.appspot.com",
//   messagingSenderId: "100721303274",
//   appId: "1:100721303274:web:4508f7faa8507cd4404193",
//   measurementId: "G-9Y88T0Q0NW"
// };

//stage
/*
const firebaseConfig = {
  apiKey: "AIzaSyDyHIPp_AwSnx2wS94cFYRHcmz1nolWK3Q",
  authDomain: "m68x-stage-21wk39.firebaseapp.com",
  projectId: "m68x-stage-21wk39",
  storageBucket: "m68x-stage-21wk39.appspot.com",
  messagingSenderId: "170747783644",
  appId: "1:170747783644:web:3105e3a4c840cd2c810719",
  measurementId: "G-45CPVHGNWH"
};
*/

//release
const firebaseConfig = {
    apiKey: "AIzaSyDxdpWeo3j-STaxtxnlK0QYn_7VcmzEi5Y",
    authDomain: "m68x-release-21wk47.firebaseapp.com",
    projectId: "m68x-release-21wk47",
    storageBucket: "m68x-release-21wk47.appspot.com",
    messagingSenderId: "520978259818",
    appId: "1:520978259818:web:fc06bc1fff0b6e2b068f13",
    measurementId: "G-EZCJJB0GQB"
};


firebase.initializeApp(firebaseConfig);
// const customersCollectionRef = firebase.firestore().collection("users");
// const customersDocRef = customersCollectionRef.doc("json");
// const allCustomersCollectionRef = customersDocRef.collection("users");
const customersDocRef =  firebase.firestore().collection("users");

//const robotsCollectionRef = firebase.firestore().collection("robots");
//const robotsDocRef = robotsCollectionRef.doc("json");
//const allRobotsCollectionRef = robotsDocRef.collection('robots');

const accountsCollectionRef = firebase.firestore().collection("users");
const accountsDocRef = accountsCollectionRef.doc("json");
const allAccountsCollectionRef = accountsDocRef.collection("users");

const robotsDocRef = firebase.firestore().collection("robots");

//REFERENCE AUTH
const auth = firebase.auth();

export const signInWithEmailPassword = async (email, password) => {
  return await auth.signInWithEmailAndPassword(email, password);
}


export async function getDealer(uid) {
  let doc = await firebase.firestore().collection("users").doc(uid).get();
  if (doc.exists){
    console.log("getDealer:", doc.data());
    console.log("getDealer:", doc.data().dealer);
    return doc.data().dealer;
  }
  throw new Error("No such document");
  
  //console.log("getDealer:", err);
}

export async function checkUserRole(uid) {
  try {
      let role = await getValues(uid);
      console.log("checkUserRole role", role);
      return role;
  } catch(err) {
      console.log("ERROR:", err);
  }
}
async function getValues(uid) {
  let doc = await firebase.firestore().collection("users").doc(uid).get();
  if (doc.exists) return doc.data().role;
  throw new Error("No such document");
}
export async function activateRobot(robots, date, storage_zip){
  //Activate robot
  //0. stamp selling date and warranty_end_date
  //1. set sim card number
  console.log("activateRobot:", robots);
  var db = firebase.firestore();
  var batch = db.batch();

  robots.forEach(robot => {
    console.log("activateRobot robot2:", robot);
    var robotRef = db.collection("robots").doc(robot.id);
    let sim_number = robot.sim_number;
    let selling_date = firebase.firestore.Timestamp.fromDate(new Date(date));
    let war_date = firebase.firestore.Timestamp.fromDate(new Date(date)).toDate();
    war_date.setSeconds(war_date.getSeconds() + 60*60*24*365*2);
    batch.set(robotRef, 
      {warranty:{
        activate:true,
        sim_number:sim_number, 
        storage_zip:storage_zip,
        selling_date:selling_date,
        warranty_end_date:war_date}
      }, { merge: true });
  });
  try{
    var result = await batch.commit();
    console.log("activateRobot:", result);
  }catch (error) {
    console.log(`Error: ${error}`);  
  }
}
export async function transferRobot2(robots, company, dealer) {
  //switch company
  //0. robots to transfer "robotData"
  //1. change robot company field "companyName"
  //2. add myself to robot dealer "array"
  console.log("transferRobot2:", robots);
  console.log("transferRobot2:", company);
  console.log("transferRobot2:", dealer);
  var db = firebase.firestore();
  var batch = db.batch();
  robots.forEach(robot => {
    console.log("transferRobot2 robot:", robot);
    var robotRef = db.collection("robots").doc(robot.id);
    batch.set(robotRef, {company: company, dealer:firebase.firestore.FieldValue.arrayUnion(dealer)}, { merge: true });
  });

  try{
    var result = await batch.commit();
    console.log("transferRobot:", result);
  }catch (error) {
    console.log(`Error: ${error}`);  
  }
}


export async function transferRobot(robots, company, dealer) {
  //switch company
  //0. robots to transfer "robotData"
  //1. change robot company field "companyName"
  //2. add myself to robot dealer "array"
  console.log("transferRobot:", robots);
  console.log("transferRobot:", company);
  console.log("transferRobot:", dealer);
  var db = firebase.firestore();
  var batch = db.batch();
  robots.forEach(robot => {
    console.log("transferRobot robot:", robot);
    var robotRef = db.collection("robots").doc(robot);
    batch.set(robotRef, {company: company, dealer:firebase.firestore.FieldValue.arrayUnion(dealer)}, { merge: true });
  });

  try{
    var result = await batch.commit();
    console.log("transferRobot:", result);
  }catch (error) {
    console.log(`Error: ${error}`);  
  }
}

export const registerWithEmailPassword = async (email, password, displayName) => {
  await auth.createUserWithEmailAndPassword(email, password);
  const user = auth.currentUser;
  await user.updateProfile({ displayName })
  return user;
}

export const registerWithEmailPassword_customer_funcs = async (email, password, newCustomer) => {
  const functions = firebase.functions();
  const triggerCallable = functions.httpsCallable('create_customer');
  //newCustomer['customer_password'] = 'abcd1234';
  console.log("registerWithEmailPassword_customer_funcs:", JSON.stringify(newCustomer));
  let sendData = newCustomer; // 要送出去的資料
  let result = await triggerCallable(sendData);
  console.log("checkResult:", JSON.stringify(result));
  return result;
}

export const registerWithEmailPassword_account_funcs = async (email, password, newAccount) => {
  const functions = firebase.functions();
  const triggerCallable = functions.httpsCallable('create_account');
  //newCustomer['customer_password'] = 'abcd1234';
  newAccount['role'] = newAccount.customer_dealer ? 'L2':'L3';
  console.log("registerWithEmailPassword_account_funcs:", JSON.stringify(newAccount));
  let sendData = newAccount; // 要送出去的資料
  let result = await triggerCallable(sendData);
  console.log("checkResult:", JSON.stringify(result));
  return result;
}

export const updateUserInfoApi = async (email, password, displayName) => {
  const user = auth.currentUser;
  if(displayName)
    await user.updateProfile({displayName });
    // 等於{displayName:displayName}
  if(email)
    await user.updateEmail(String(email));
  if(password)
    await user.updatePassword(password);
  return user;
}

export const signOut = () => {
  auth.signOut();
}

export const checkLoginApi = () => {
  const user = auth.currentUser;
  if(user){
    return user.uid? true : false;
  }else{
    return false
  }
}

export const getRobotById = async (robots) => {
  const doc = await robotsDocRef.doc(robots).get();
  return doc.data()
}