import { Layout } from 'antd';
import { useContext , useEffect} from "react"; 
import NavBar from "../../components/NavBar";
import AutoTestConnect from "../../components/test/AutoTestConnect";

const { Content } = Layout;

function AutoTestCon() {
   return (
      <Layout className="container main-layout">
            <NavBar/>
         <Layout className="bg-gray">
            <Content className="layout-content">
               <AutoTestConnect/>
            </Content>
         </Layout>
      </Layout>
   );
}

export default AutoTestCon;