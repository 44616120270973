import {
  SET_PAGE_TITLE,
  SET_PAGE_CONTENT,
  SET_DATA_DETAIL,
  SET_NAVBAR_ACTIVEITEM,
  BEGIN_DATA_REQUEST,
  SUCCESS_DATA_REQUEST,
  FAIL_DATA_REQUEST,
  BEGIN_LOGIN_REQUEST,
  SUCCESS_LOGIN_REQUEST,
  FAIL_LOGIN_REQUEST,
  LOGOUT_REQUEST,
  REMEMBER_LOGIN,
  SET_SELECTED_TEST,
  SAVE_ROBOT,
  BEGIN_UPDATE_ROBOT,
  SUCCESS_UPDATE_ROBOT,
  FAIL_UPDATE_ROBOT
  } from "../utils/constants";
  
  import {
    signInWithEmailPassword,
    signOut,
    checkLoginApi,
    getDealer,
    getRobotById
  } from "../Api";

  import firebase from 'firebase/app'
import 'firebase/auth';
import 'firebase/firestore';
  
  export const setPage = async (dispatch, url, title) => {
    dispatch({
      type: SET_PAGE_TITLE,
      payload: title,
    });
    try {
      dispatch({
        type: SET_PAGE_CONTENT,
        payload: { title },
      });
      dispatch({
        type: SET_NAVBAR_ACTIVEITEM,
        payload: url,
      });
    } catch (error) {
      console.log(error);
    }
  }

  export const setTestDetail =  async (dispatch,test) => {
    dispatch({ type: BEGIN_DATA_REQUEST });
    try {
        dispatch({
          type: SET_DATA_DETAIL,
          payload: test
        })
      dispatch({ type: SUCCESS_DATA_REQUEST });
    } catch (error) {
      console.log(error);
      dispatch({ type: FAIL_DATA_REQUEST, payload: error });
    }
  }
  
  export const loginToFirebase = async (dispatch, userInfo) => {
    dispatch({ type: BEGIN_LOGIN_REQUEST });
    //console.log('loginToFirebase:', JSON.stringify(userInfo));
    try {
      const user = await signInWithEmailPassword(userInfo.email, userInfo.password);
      console.log('user', user);
      console.log('user.user:', user.user);

      const token = await firebase.auth().currentUser.getIdTokenResult();
      console.log('user.getIdTokenResult()', token);

      const dealers = await getDealer(user.user.uid);
      dispatch({
        type: SUCCESS_LOGIN_REQUEST,
        payload: [user.user.providerData[0],token, dealers ,user.user.uid],
      })  
      
      console.log('payload,', [user.user.providerData[0],token]);


      return user;
    } catch (e) {
      dispatch({
        type: FAIL_LOGIN_REQUEST,
        payload: e.message
      })
      console.log(e)
      return null;
    }
  }

  export const rememberLoginUser = (dispatch, remember) => {
    dispatch({
      type: REMEMBER_LOGIN,
      payload: remember,
    })
  }
  
  export const logoutFromFirebase = async (dispatch) => {
    signOut();
    dispatch({ type: LOGOUT_REQUEST });
  }
  
  export const checkLogin = (dispatch) => {
    const isLogin = checkLoginApi();
    if(!isLogin) {
      dispatch({ type: LOGOUT_REQUEST });    
    }
    return isLogin;
  }

  export const saveSelectedTest = (dispatch, selTest) => {
    dispatch({
      type: SET_SELECTED_TEST,
      payload: selTest
    })  
  }  

  export const saveRobot = (dispatch, robotPSN) => {
    dispatch({
      type: SAVE_ROBOT,
      payload: robotPSN,
    });
    //localStorage.setItem('robot', JSON.stringify(robot));
  }

  export const requestRobotDetail = async (dispatch, robot) => {
    dispatch({ type: BEGIN_UPDATE_ROBOT });
    try {
      const robot = await getRobotById(robot);
      dispatch({ 
        type: SUCCESS_UPDATE_ROBOT,
        payload: robot,
      });
    } catch (error) {
      dispatch({ 
        type: FAIL_UPDATE_ROBOT, 
        payload: error 
      });
    }
  }

  export const setRobotDetail =  async (dispatch, robot) => {
    //console.log('setCustomerDetail: ', user);
    dispatch({ type: BEGIN_DATA_REQUEST });
    try {
        dispatch({
          type: SET_DATA_DETAIL,
          payload: robot
        })
      dispatch({ type: SUCCESS_DATA_REQUEST });
    } catch (error) {
      console.log(error);
      dispatch({ type: FAIL_DATA_REQUEST, payload: error });
    }
  }