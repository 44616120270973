import React from 'react';
import { Link } from 'react-router-dom';
import { Layout } from "antd";
import * as QueryString from "query-string";
import LoginCard from "../components/LoginCard";
const { Content } = Layout;

function Login(props) {
  const { redirect } = QueryString.parse(props.location.search);
  
  return (
    <Layout className="container main-layout">
      <Content>
        <LoginCard redirect={redirect}/>
      </Content>
    </Layout>
  );
}

export default Login;
