import { createContext, useReducer, useState } from "react";
import {
   SET_PAGE_TITLE,
   SET_PAGE_CONTENT,
   SET_DATA_DETAIL,
   SET_NAVBAR_ACTIVEITEM,
   BEGIN_DATA_REQUEST,
   SUCCESS_DATA_REQUEST,
   FAIL_DATA_REQUEST,
   BEGIN_LOGIN_REQUEST,
   SUCCESS_LOGIN_REQUEST,
   FAIL_LOGIN_REQUEST,
   LOGOUT_REQUEST,
   REMEMBER_LOGIN,
   BEGIN_UPDATE_USERINFO,
   SUCCESS_UPDATE_USERINFO,
   BEGIN_REQUEST_USERINFO,
   SUCCESS_REQUEST_USERINFO,
   FAIL_REQUEST_USERINFO,
   SET_SELECTED_TEST,
   SAVE_ROBOT,
   BEGIN_UPDATE_ROBOT,
   SUCCESS_UPDATE_ROBOT,
   FAIL_UPDATE_ROBOT,
} from "../utils/constants"

export const StoreContext = createContext();

let userInfo={};
let robotPSN={};

const initialState = {
   allTests: [],
    testDetail: {
      test: {},
   },
   allRobots: [],
   robotDetail: {
      robot: {}
   },
   navBar: {
      activeItem: "/",
   },
   userSignin: {
      loading: false,
      userInfo,
      remember: true,
      error: "",
   },
};

function reducer(state, action) {

   //console.log('reducer: ', action.payload);

   switch (action.type) {
      case SET_PAGE_TITLE:
         return {
            ...state,
            page: {
               ...state.page,
               title: action.payload,
            },
         };
      case SET_PAGE_CONTENT:
         return {
            ...state,
            page: {
               ...state.page,
               user: action.payload,
            },
         };
      case SET_NAVBAR_ACTIVEITEM:
         return {
            ...state,
            navBar: {
               activeItem: action.payload
            }
         };
      case SET_DATA_DETAIL:
         return {
            ...state,
            testDetail: { ...state.testDetail, test: action.payload },
         };
      case BEGIN_DATA_REQUEST:
         return {
            ...state,
            requestTests: { ...state.requestTests, loading: true },
         };
      case SUCCESS_DATA_REQUEST:
         return {
            ...state,
            requestTests: { ...state.requestTests, loading: false },
         };
      case FAIL_DATA_REQUEST:
         return {
            ...state,
            requestTests: {
               ...state.requestTests,
               loading: false,
               error: action.payload,
            },
         };
      case BEGIN_LOGIN_REQUEST:
         return { ...state, userSignin: { ...state.userSignin, loading: true } };
      case SUCCESS_LOGIN_REQUEST:
         return {
            ...state,
            userSignin: {
               ...state.userSignin,
               loading: false,
               userInfo: action.payload,
               error: "",
            },
         };
      case FAIL_LOGIN_REQUEST:
         return {
            ...state,
            userSignin: {
               ...state.userSignin,
               loading: false,
               userInfo: null,
               error: action.payload,
            },
         };
      case BEGIN_UPDATE_USERINFO:
         return { ...state, userSignin: { ...state.userSignin, loading: true } };
      case SUCCESS_UPDATE_USERINFO:
         return {
            ...state,
            userSignin: {
               ...state.userSignin,
               loading: false,
               userInfo: action.payload,
               error: "",
            },
         };
      case FAIL_REQUEST_USERINFO:
         return {
            ...state,
            userSignin: {
               ...state.userSignin,
               loading: false,
               error: action.payload,
            },
         };
      case BEGIN_REQUEST_USERINFO:
         return { ...state, userSignin: { ...state.userSignin } };
      case SUCCESS_REQUEST_USERINFO:
         return {
            ...state,
            userSignin: {
               ...state.userSignin,
               userInfo: action.payload,
               error: "",
            },
         };
      case FAIL_REQUEST_USERINFO:
         return {
            ...state,
            userSignin: {
               ...state.userSignin,
               error: action.payload,
            },
         };
      case LOGOUT_REQUEST:
         return {
            ...state,
            userSignin: {
               ...state.userSignin,
               userInfo: null,
            },
         };
      case REMEMBER_LOGIN:
         return {
            ...state,
            userSignin: {
               ...state.userSignin,
               remember: action.payload,
            },
         };
      case SET_SELECTED_TEST:
         return {
            ...state,
            selTest: action.payload
         };
      case SAVE_ROBOT:
         return { ...state, robotDetail: { robot: action.payload } };
      case BEGIN_UPDATE_ROBOT:
         return { ...state, robotDetail: { ...state.robotDetail } };
      case SUCCESS_UPDATE_ROBOT:
         return {
            ...state,
            robotDetail: {
               robotPSN: action.payload,
            },
         };
      case FAIL_UPDATE_ROBOT:
         return {
            ...state,
            robotDetail: {
               ...state.robotDetail,
               error: action.payload,
            },
         };
      default: 
      return state;
   }
}

export function StoreProvider(props) {
   const [state, dispatch] = useReducer(reducer, initialState);
   const value = { state, dispatch };

   //console.log('StoreProvider enter:', state);
   //console.log('initialState enter:', initialState);
   
   return (
      <StoreContext.Provider value={value}>
         {props.children}
      </StoreContext.Provider>
   );
}